import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { ErrorComponent } from './pages/error/error.component';
import { RouteGuardService } from './services/route-guard.service';

export const routes: Routes = [
  {
    path: '',
    canActivate: [RouteGuardService],
    loadChildren: './pages/pages.module#PagesModule'
  },
  { path: 'login', loadChildren: './pages/login/login.module#LoginModule' },
  // Renvoie vers la page d'erreur si la route est inconnue
  { path: '**', component: ErrorComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules
});
