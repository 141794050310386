import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Composant affiché par défaut si la route n'existe pas sur le site
 */
@Component({
  selector: 'app-error',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {

    /**
     * @constructor
     * @param router Permettra de rediriger l'utilisateur lorsqu'il cliquera sur le bouton
     */
    constructor(private router: Router) {
    }

    ngOnInit() {
        // Stop le preloader du site
        document.getElementById('preloader').style['display'] = 'none';
    }

    /**
     * Fonction exécutée lorsque l'utilisateur clique sur le bouton de retour vers la page d'accueil
     */
    backHome(): void {
        this.router.navigate(['pages']);
    }

}
