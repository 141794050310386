// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  server: "https://services-accobams.sinay.ai",
  urlPlatform: "https://platform.sinay.ai",
  urlLoginIframe: "https://platform.sinay.ai/account/auth/login",
  urlLogoutIframe: "https://platform.sinay.ai/account/auth/logout",
  resourceServer: "https://platform.sinay.ai/resources-management",
  userServer: "https://platform.sinay.ai/accountmanagement",
  workspaceId: "ws-cu-860386174",
  appLogo: "images/logo/(iLRK7NpZ,IzLogo_ACCOBAMS.jpg",
  appCableServer: "https://app-cable.sinay.fr",
  apiAppCableServer: "https://api-app-cable.sinay.fr",
  urlHotspot: "https://noise-hotspot.sinay.ai",
  gisToolsUrl: "https://sig-clone-dev.sinay.ai",
  hubUrl: "https://hub.sinay.ai",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
