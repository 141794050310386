import { Injectable } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from 'angular-2-local-storage';
import { User } from '../models/user';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

/**
 * Service permettant la sauvegarde, la récupération et la suppression d'un utilisateur en mémoire
 * Permet aussi la modification de certaines infos en base
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {
  static token: string = null;
  static user: User = null;
  static roles = [];
  static subscriptions = [];

  URL_SERVER = environment.server;

  /**
   * @constructor
   * @param localStorageService Service permettant d'accèder au localStorage, à la mémoire de l'application
   * @param http Permet d'exécuter des requêtes HTTP
   */
  constructor(
    private localStorageService: LocalStorageService,
    public http: HttpClient
  ) {
    // Affecte la valeur des variables dans le localStorage aux variables de ce service si l'utilisateur est déjà connecté
    if (UserService.token === null || UserService.user === null) {
      UserService.user = this.localStorageService.get<User>('user');
      UserService.token = this.localStorageService.get<string>('token');
      UserService.roles = this.localStorageService.get<string[]>('roles');
      UserService.subscriptions = this.localStorageService.get<string[]>(
        'subscriptions'
      );
    }
  }

  /**
   * Sauvegarde uniquement l'objet User représentant l'utilisateur, utilisé après la mise à jour des CGU
   * @param user Object User à sauvegarder avec les nouvelles infos
   */
  public updateUser(user: User): boolean {
    UserService.user = user;
    const saveUser = this.localStorageService.set('user', user);

    return saveUser !== null;
  }

  /**
   * Ajoute l'utilisateur et ses infos en mémoire et dans ce service
   * Utilisé pour la connexion
   * @param user Objet User avec les infos de l'utilisateur
   * @param token Jeton JWT
   * @param roles Le ou les rôles à sauvegarder
   * @param subscriptions Abonnements à sauvegarder
   */
  public saveUser(
    user: User,
    token: string,
    roles: string[],
    subscriptions: any[]
  ): boolean {
    UserService.token = token;
    UserService.user = user;
    UserService.roles = roles;
    UserService.subscriptions = subscriptions;

    const saveToken = this.localStorageService.set('token', token);
    const saveUser = this.localStorageService.set('user', user);
    const saveRoles = this.localStorageService.set('roles', roles);
    const saveSubscriptions = this.localStorageService.set(
      'subscriptions',
      subscriptions
    );

    return saveToken && saveUser && saveRoles && saveSubscriptions;
  }

  /**
   * Supprime l'utilisateur actuellement connecté, dans le localStorage et dans ce service
   * Utilisé pour déconnecter
   */
  public removeUser(): boolean {
    UserService.user = null;
    UserService.token = null;
    UserService.roles = null;
    UserService.subscriptions = null;

    const removeUser = this.localStorageService.remove('user');
    const removeToken = this.localStorageService.remove('token');
    const removeRoles = this.localStorageService.remove('roles');
    const removesubscriptions = this.localStorageService.remove(
      'subscriptions'
    );

    return removeToken && removeUser && removeRoles && removesubscriptions;
  }

  /**
   * Vérifie l'expiration de la session de l'utilisateur connecté, c-à-d son jeton JWT
   */
  public isSessionExpired(): boolean {
    try {
      if (UserService.token === null || UserService.user === null) {
        return true;
      }

      const jwtHelper: JwtHelperService = new JwtHelperService();

      if (jwtHelper.isTokenExpired(UserService.token.toString())) {
        return true;
      }
      return false;
    } catch (ex) {
      return true;
    }
  }

  /**
   * Renvoie le jeton actuel de l'utilisateur connecté
   */
  public getToken(): string {
    return UserService.token;
  }

  /**
   * Renvoie un objet User avec les infos de l'utilisateur connecté
   */
  public getUser(): User {
    return UserService.user;
  }

  /**
   * Renvoie le ou les rôles de l'utilisateur
   */
  public getRoles() {
    return UserService.roles;
  }

  /**
   * Renvoie les abonnements de l'utilisateur
   */
  public getSubscriptions() {
    return UserService.subscriptions;
  }

  /**
   * Modifie le statut des CGU de l'utilisateur passé en paramètre
   * @param username Email de l'utilisateur à modifier
   */
  public acceptCGU(username: string) {
    const url = `${this.URL_SERVER}/account/acceptCGU?username=` + username;
    return this.http.post(url, {});
  }

  /**
   * Modifie l'opt-in de l'utilisateur passé en paramètre
   * @param username Email de l'utilisateur à modifier
   * @param status Boolean d'acceptation ou de refus de l'opt-in
   */
  public updateOptIn(username: string, status: boolean) {
    const url = `${
      this.URL_SERVER
    }/account/updateOptIn?username=${username}&newStatus=${status}`;

    return this.http.post(url, {});
  }

  /**
   * Vérifie si l'utilisateur possède le rôle passé en paramètre
   * @param roleIn Rôle à vérifier
   * @returns boolean
   */
  public hasRole(roleIn: string): boolean {
    if (!roleIn) {
      return false;
    }
    if (UserService.roles === null) {
      return false;
    }
    if (roleIn === 'ROLE_ANY') {
      return true;
    }
    if (UserService === null || UserService.roles === null) {
      return false;
    }
    for (const role of UserService.roles) {
      if (roleIn === role['authority']) {
        return true;
      }
    }
    return false;
  }

  /**
   * Demande un nouveau de mot de passe au serveur qui envoie par email le nouveau avec un lien de confirmation
   * @param email Email du compte voulant un nouveau mot de passe
   */
  public getNewPassword(email: string) {
    const url = this.URL_SERVER + '/account/getNewPassword?email=' + email;
    return this.http.get(url);
  }

  /**
   * Retourne la page par défaut de l'utilisateur après la connexion, amener à changer
   */
  public getDefaultPath() {
    return '/map';
  }

}
