import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';

/**
 * Service permettant d'accepter ou de refuser l'accès à une page selon le rôle, les abonnements et l'état du jeton de l'utilisateur
 */
@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  /**
   * Vérifie si l'utilisateur possède un token toujours actif et s'il peut accéder aux différentes pages de l'application
   * @param next Route cible
   * @param state État de la route
   */
  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const role = this.userService.getRoles();
    const subscriptions = this.userService.getSubscriptions();
    const url = state.url;
    // Prend en charge toutes les redirections si l'utilisateur n'est pas connecté
    if (this.userService.isSessionExpired()) {
      this.router.navigate(['/login']);
      return false;
    }

    if (!this.hasAccess(role, subscriptions, url)) {
      window.location.href = environment.urlPlatform + '/platform/pages/home';
      return false;
    }

    return true;
  }

  /**
   * Vérifie si l'utilisateur à le droit d'accéder à la page cible
   * @param role Rôle de l'utilisateur
   * @param subscriptions Abonnements de l'utilisateur
   * @param url URL de la page cible
   */
  private hasAccess(role, subscriptions, url): boolean {
    // Prend en charge toutes les redirections selon le rôle et les abonnements de l'utilisateur
    if (role) {
      /*for (let i = 0; i < role.length; i++) {
        if (role[i].authority === 'ROLE_ADMIN' || role[i].authority === 'ROLE_SINAY') {
          return true;
        }
        if (role[i].authority === 'ROLE_CUSTOMER') {
          // vérifie les accès de l'utilisateur et le renvoi sur la page home ou non en fonction
          return false;
        }
      }*/
    }
    return true;
  }
}
