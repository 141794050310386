import { Component, ViewEncapsulation } from '@angular/core';

/**
 * Composant principal de l'application, redirige vers tous les autres composants et fourni des fonctions communes
 */
@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  template: `
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
}
