import { Injectable } from '@angular/core';
import { Color, HEX } from './app.color';

/**
 * Classe contenant la configuration de l'application et certaines fonctions communes à plusieurs composants
 */
@Injectable()
export class AppConfig {
  sassVariables: any;
  config: any;

  constructor() {
    this.config = {
      name: 'Sinay Platform',
      title: 'Big data platform developped by Sinay',
      version: '2.3.0',
    };
  }

  /**
   * Passe une couleur HEXA en RGBA
   * @param color Couleur en hexadécimal à modifier
   * @param opacity Opacité à appliquer sur la couleur
   */
  rgba(color, opacity) {
    if (color.indexOf('#') >= 0) {
      if (color.slice(1).length === 3) {
        color = '#' + color.slice(1) + '' + color.slice(1);
      }
      return new Color(new HEX(color)).setAlpha(opacity).toString();
    } else {
      console.log('incorrect color: ' + color);
      return 'rgba(255,255,255,0.7)';
    }
  }
}
